<template>
  <b-card-header
    v-b-toggle="`accordion-${index}`"
    header-tag="header"
    class="block-card-header"
    role="tab"
  >
    <b-form-checkbox
      v-model="object[index].active"
      name="active"
      switch
    ></b-form-checkbox>
    <span class="text text-dark">{{ translate(object[index].title) }} </span>
    <b-badge class="overlay-category">{{ object[index].type }}</b-badge>
    <font-awesome-icon
      class="action-icon ml-3 "
      icon="copy"
      @click="duplicateItem()"
    />
    <font-awesome-icon
      class="action-icon ml-3 text-danger"
      icon="trash"
      @click="deleteItem()"
      ref="btnShowDelete"
    />
    <div class="header-items-right">
      <font-awesome-icon class="handle drag-icon" icon="grip-vertical" />
    </div>
  </b-card-header>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";

export default {
  props: ["object", "index"],
  components: {},
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
    async deleteItem() {
      const result = await this.$bvModal.msgBoxConfirm(
        "Are you sure you want to delete this block?",
        {
          title: "Confirm delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (result) {
        this.object.splice(this.index, 1);
      }
    },
    async duplicateItem() {
      this.object.push({ ...this.object[this.index] });
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

.block-card-header {
  display: flex;
  align-items: center;
}

.block-action-buttons {
  float: right;
  margin-bottom: 20px;
}

.block-action-buttons > * {
  margin-left: 20px;
}

.header-items-right {
  margin-left: auto;
}

.header-items-right > * {
  margin-left: 10px;
}

.drag-icon {
  cursor: move;
}

.handle {
  float: left;
}

.text {
  margin: 20px;
}
</style>
